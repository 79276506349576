import React from "react"
import styled from "styled-components"
import { Routes, Route, useLocation } from "react-router-dom"
import OverlayItem from "./OverlayItem"
import ParallaxLanding from "../ParallaxLanding/index"
import { Helmet } from "react-helmet"

const OverlayContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	pointer-events: none;

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: calc(100% - 95px);
		z-index: 150;
	}

	@media only screen and (max-width: 640px) {
		width: calc(100% - 80px);
	}
`

function Overlay({ overlayRef, canvasRef, menuRef, view360Ref, cameraRotate, audio, texts, lang, setAudioPlaying }) {
	const location = useLocation()
	return (
		<OverlayContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="Cabinet du Dr. Patrick C. Curiel. Spécialiste de l'orthodontie invisible." />
				<link rel="canonical" href="https://drcuriel.com/" />
			</Helmet>
			<Routes loaction={location} key={location.pathname}>
				<Route path="/home" element={<OverlayItem title={texts["menu-1"]} canvasRef={canvasRef} cameraRotate={cameraRotate} ref={overlayRef} />} />
				<Route
					path="/docteurs"
					element={<OverlayItem title={texts["menu-2"]} texts={texts} canvasRef={canvasRef} cameraRotate={cameraRotate} ref={overlayRef} />}
				/>
				<Route
					path="/expertise"
					element={<OverlayItem title={texts["menu-3"]} texts={texts} canvasRef={canvasRef} cameraRotate={cameraRotate} ref={overlayRef} />}
				/>
				<Route
					path="/enfants"
					element={<OverlayItem title={texts["menu-7"]} texts={texts} canvasRef={canvasRef} cameraRotate={cameraRotate} ref={overlayRef} />}
				/>
				<Route
					path="/prendre-rendez-vous"
					element={
						<OverlayItem title={texts["menu-4"]} texts={texts} canvasRef={canvasRef} cameraRotate={cameraRotate} lang={lang} ref={overlayRef} />
					}
				/>
				<Route
					path="/contact"
					element={<OverlayItem title={texts["menu-5"]} canvasRef={canvasRef} cameraRotate={cameraRotate} lang={lang} ref={overlayRef} />}
				/>
				<Route
					path="/"
					element={<ParallaxLanding menuRef={menuRef} setAudioPlaying={setAudioPlaying} view360Ref={view360Ref} audio={audio} />}
				/>
			</Routes>
		</OverlayContainer>
	)
}

export default Overlay
