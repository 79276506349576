import React, { useRef } from "react"
import styled from "styled-components"
import ExpertisePart from "./ExpertisePart"
import { Helmet } from "react-helmet"

import CurielPDF from "../assets/curiel.pdf"


const EnfantsContainer = styled.div`
	width: 85%;
	padding-left: calc(20% + 25px);
	box-sizing: content-box;
	padding-top: 7%;
	.title{
		width: 75%;
		font-weight: bold;
		letter-spacing: 1px;
		font-size: 22px;
	}
	.subtitle {
		width: 80%;
		margin-top: 25px;
		margin-bottom: 25px;
		font-size: 17px;
	}

	a {
		display: inline-block;
		color: white;
		margin-bottom: 50px;
	}

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: 90%;
		padding-left: calc(10% + 25px);
		.subtitle {
			width: 95%;
		}
	}
	@media only screen and (max-width: 640px) {
		padding-left: calc(5% + 25px);
	}
`

function Enfants({ texts }) {
	const skill1Ref = useRef(null)
	const skill2Ref = useRef(null)
	return (
		<EnfantsContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel | Enfants - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="Les traitements interceptifs permettent de guider le développement des mâchoires afin de créer la place nécessaire pour accueillir toutes les dents définitives et d’améliorer l’occlusion c'est à dire les rapport entre le maxillaire et la mandibule." />
				<link rel="canonical" href="https://drcuriel.com/enfants" />
			</Helmet>
			<h2 className="title">{texts["enfants-title"]}</h2>
			<p className="subtitle">{texts["enfants-subtitle"]}</p>
			<a href={CurielPDF} target="_blank" without rel="noreferrer">{texts["enfants-linktext"]}</a>
			<ExpertisePart skill={texts["enfants-1-title"]} text={texts["enfants-1-text"]} ref={skill1Ref} others={[skill2Ref]} />
			<ExpertisePart skill={texts["enfants-2-title"]} text={texts["enfants-2-text"]} image={"https://curiel-adminzone-6pvs8.ondigitalocean.app/uploads/enfants_48ef6017d2.jpg?updated_at=2023-05-23T13:14:58.645Z"} ref={skill2Ref} others={[skill1Ref]} />
		</EnfantsContainer>
	)
}

export default Enfants
