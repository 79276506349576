import React, { useState, useRef, forwardRef, useImperativeHandle } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap, { Power1 } from "gsap"

const MenuContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 120px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Trade Gothic Extended";
	font-size: 11.5px;
	letter-spacing: 0.5px;
	pointer-events: none;
	overflow: hidden;
	z-index: 150;

	.logoContainer {
		width: 180px;
		pointer-events: auto;

		img {
			width: 100%;
			margin-left: 40px;
			padding: 10px;
			cursor: pointer;
		}
	}
	.navOuterContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	.navContainer {
		width: 675px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 40px;
		pointer-events: auto;

		> div {
			font-size: 16px;
			cursor: pointer;
			&:hover {
				font-weight: 600;
			}
		}

	}
	.langContainer{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 65px;
		height: 35px;
		padding-right: 40px;
		transform: translateY(10px);
		div {
			cursor: pointer;
			pointer-events: auto;
			text-underline-offset: 3px;
		}
		.underline {
			text-decoration: underline;
		}
	}
	.menuBackContainer {
		display: none;
	}

	.connection {
		position: relative;
		img {
			width: 30px;
		}
		&::before {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			padding: 5px 10px;
			font-weight: 200;
			background: rgba(255, 255, 2555, 0.3);
			color: white;
			white-space: nowrap;
			content: "ESPACE CLIENT";
		}
		&:hover::before {
			display: block;
		}
	}

	// RESPONSIVE
	@media only screen and (min-width: 1439px) {
	.navContainer {
		> div {
			font-size: 13px;
		}
	}
	}
	@media only screen and (max-width: 1439px) {
		height: 100%;

		.menuBackContainer {
			position: absolute;
			top: 30px;
			right: 20px;
			display: block;
			z-index: 100;
			padding: 15px;
			pointer-events: auto;

			.burger-wrapper {
				cursor: pointer;
				margin: auto;
				width: 30px;
				height: 20px;
				pointer-events: none;
			}
			.burger-wrapper .hamburger {
				background: white;
				width: 25px;
				height: 2px;
				position: relative;
				transition: background 10ms 300ms ease;
			}
			.burger-wrapper .hamburger:before,
			.burger-wrapper .hamburger:after {
				transition: top 300ms 350ms ease, transform 300ms 50ms ease;
				position: absolute;
				background: white;
				width: 25px;
				height: 2px;
				content: "";
			}
			.burger-wrapper .hamburger:before {
				top: -10px;
			}
			.burger-wrapper .hamburger:after {
				top: 10px;
			}

			.menu-trigger {
				pointer-events: none;
			}
			.menu-trigger:checked ~ .burger-wrapper .hamburger {
				background: transparent;
			}
			.menu-trigger:checked ~ .burger-wrapper .hamburger:after,
			.menu-trigger:checked ~ .burger-wrapper .hamburger:before {
				transition: top 300ms 50ms ease, transform 300ms 350ms ease;
				top: 0;
			}
			.menu-trigger:checked ~ .burger-wrapper .hamburger:before {
				transform: rotate(45deg);
			}
			.menu-trigger:checked ~ .burger-wrapper .hamburger:after {
				transform: rotate(-45deg);
			}

			.hidden {
				display: none;
			}
		}
		.logoContainer {
			position: absolute;
			top: 25px;
			left: 25px;
			z-index: 150;
			img {
				margin: 0;
			}
		}
		.navOuterContainer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			backdrop-filter: blur(30px) brightness(80%);
			transform: translateX(100%);
			pointer-events: auto;
		}
		.langContainer {
			width: 525px;
			justify-content: flex-start;
			padding: 0;
			flex-direction: row;
		}
		.navContainer {
			flex-direction: column;
			align-items: start;
			height: 50%;
			width: 100%;
			margin-left: 15%;
			font-size: 19px;
		}
		.connection {
			display: flex;
			a {
				padding-right: 25px;
			}
			img {
				width: 50px;
			}
			.langContainer {
				width: 75px;
				display: flex;
				justify-content: space-between;
			}
			&:hover::before {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 640px) {
		.logoContainer {
			width: 150px;
			top: 17px;
			left: 17px;
		}
		.menuBackContainer {
			top: 21px;
			right: 15px;

			.burger-wrapper .hamburger {
				background: white;
				width: 20px;
				height: 1px;
				position: relative;
				transition: background 10ms 300ms ease;
			}
			.burger-wrapper .hamburger:before,
			.burger-wrapper .hamburger:after {
				transition: top 300ms 350ms ease, transform 300ms 50ms ease;
				position: absolute;
				background: white;
				width: 20px;
				height: 1px;
				content: "";
			}
			.burger-wrapper .hamburger:before {
				top: -8px;
			}
			.burger-wrapper .hamburger:after {
				top: 8px;
			}
		}
	}
`

const Menu = forwardRef(({ view360Ref, overlayRef, transitionToLanding, texts, setLang, lang}, ref) => {
	const [canSwitch, setCanSwitch] = useState(true)
	const [menuIsOpen, setMenuIsOpen] = useState(false)

	const menuContainerRef = useRef(null)
	const navOuterContaineRef = useRef(null)
	const menuButtonRef = useRef(null)
	const langContainer = useRef(null)
	let navigate = useNavigate()

	const redirectTo = (path) => {
		if (window.innerWidth < 1439 && menuIsOpen) toggleMenu()
		if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== path && canSwitch) {
			view360Ref.current.slide(path)
			if (path === "home") {
				setCanSwitch(false)
				overlayRef.current?.close(`/${path}`)
				setTimeout(() => {
					setCanSwitch(true)
				}, 1000)
			} else {
				if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "home") {
					setCanSwitch(false)
					navigate(`/${path}`)
					setTimeout(() => {
						setCanSwitch(true)
					}, 1000)
				} else {
					setCanSwitch(false)
					overlayRef.current?.close(`/${path}`)
					setTimeout(() => {
						setCanSwitch(true)
					}, 2000)
				}
			}
		}
	}

	useImperativeHandle(ref, () => ({
		display: (mode) => {
			menuContainerRef.current.style.display = mode
		},
		goToLanding: () => {
			navigate("/")
		},
		redirect: (path) => {
			redirectTo(path)
		},
	}))

	const toggleMenu = () => {
		if (menuIsOpen) {
			gsap.to(navOuterContaineRef.current, { duration: 1, x: "100%", ease: Power1.easeInOut })
		} else {
			gsap.to(navOuterContaineRef.current, { duration: 1, x: 0, ease: Power1.easeInOut })
		}
		menuButtonRef.current.checked = !menuIsOpen
		setMenuIsOpen(!menuIsOpen)
	}

	return (
		<MenuContainer ref={menuContainerRef}>
			<div className="menuBackContainer" onClick={toggleMenu}>
				<input className="menu-trigger hidden" id="togglenav" type="checkbox" ref={menuButtonRef} />
				<label className="burger-wrapper" htmlFor="togglenav">
					<div className="hamburger"></div>
				</label>
			</div>
			<div className="logoContainer">
				<img
					src="logo.png"
					alt="Logo"
					onClick={() => {
						transitionToLanding()
					}}
				/>
			</div>
			<div className="navOuterContainer" ref={navOuterContaineRef}>
				<div className="navContainer">
					<div
						onClick={() => {
							redirectTo("docteurs")
						}}
					>
						{texts["menu-2"]}
					</div>
					<div
						onClick={() => {
							redirectTo("expertise")
						}}
					>
						{texts["menu-3"]}
					</div>
					<div
						onClick={() => {
							redirectTo("enfants")
						}}
					>
						{texts["menu-7"]}
					</div>
					<div
						onClick={() => {
							redirectTo("prendre-rendez-vous")
						}}
					>
						{texts["menu-4"]}
					</div>
					<div
						onClick={() => {
							redirectTo("contact")
						}}
					>
						{texts["menu-5"]}
					</div>
					<div className="connection" onMouseEnter={() => {
						if (window.innerWidth > 1439) langContainer.current.style.opacity = 0
					}}
					onMouseLeave={() => {
						langContainer.current.style.opacity = 1
					}}
					>
						<a href="https://www.anywheredolphin.com/secure/login.aspx?p=2" target="_blank" rel="noreferrer">
							<img src="connection.png" alt="Connection" />
						</a>
						{window.innerWidth < 1439 && 
				<div className="langContainer" ref={langContainer}>
					<div  className={`en ${lang === 'en' ? 'underline' : ''}`} onClick={() => {
						setLang('en')
						localStorage.setItem('lang', 'en');
					}}>EN</div>
					<div  className={`fr ${lang === 'fr' ? 'underline' : ''}`} onClick={() => {
						setLang('fr')
						localStorage.setItem('lang', 'fr');
					}}>FR</div>
					
				</div>
				}
					</div>
				</div>
				{window.innerWidth > 1439 && 
				<div className="langContainer" ref={langContainer}>
					<div  className={`en ${lang === 'en' ? 'underline' : ''}`} onClick={() => {
						setLang('en')
						localStorage.setItem('lang', 'en');
					}}>EN</div>
					<div  className={`fr ${lang === 'fr' ? 'underline' : ''}`} onClick={() => {
						setLang('fr')
						localStorage.setItem('lang', 'fr');
					}}>FR</div>
					
				</div>
				}
			</div>
		</MenuContainer>
	)
})

export default Menu
