import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react"
import styled from "styled-components"
import gsap, { Power1 } from "gsap"
import { useNavigate } from "react-router-dom"
import APropos from "./APropos"
import Expertise from "./Expertise"
import Enfants from "./Enfants"
import PrendreRendezVous from "./PrendreRendezVous"
import Contact from "./Contact"

const OverlayItemContainer = styled.div`
	width: 100%;
	height: 100%;
	backdrop-filter: blur(30px) brightness(80%);
	transform: translateX(-100%);
	pointer-events: auto;
	overflow: hidden;

	.backButtonContainer {
		height: 90px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: 20px;
		margin-bottom: 25px;
		.backButtonInnerContainer {
			display: flex;
			align-items: center;
			padding: 20px;
			cursor: pointer;
			p {
				font-size: 11px;
				letter-spacing: 5px;
			}
			img {
				width: 35px;
				margin-left: 20px;
				&:hover {
					transform: rotate(90deg);
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
	.titleContainer {
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-top: 50px;
		.line {
			width: 35%;
			height: 0.5px;
			background: white;
		}
		.title {
			margin-left: 25px;
			letter-spacing: 2px;
			font-size: 12px;
		}
	}
	.itemContainer {
		height: 80%;
		overflow-x: hidden;
		overflow-y: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	.itemContainer::-webkit-scrollbar {
		display: none;
	}
	.blackmotion {
		font-size: 12px;
		position: absolute;
		left: calc(25% + 25px);
		bottom: 15px;
		opacity: 0.75;
		color: white;
	}

	// RESPONSIVE
	@media only screen and (max-width: 1440px) {
		.itemContainer {
			height: calc(100% - 200px);
		}
	}
	@media only screen and (max-width: 1024px) {
		.titleContainer {
			.line {
				width: 10%;
				height: 0.5px;
				background: white;
			}
		}
		.itemContainer {
			height: calc(100% - 190px);
		}
		.blackmotion {
			left: calc(10% + 25px);
		
		}
	}

	@media only screen and (max-width: 640px) {
		.backButtonContainer {
			height: 75px;
			.backButtonInnerContainer {
				img {
					width: 30px;
				}
			}
		}
		.titleContainer {
			margin-top: 30px;
		}
		.itemContainer {
			height: calc(100% - 180px);
		}
	}
`

const OverlayItem = forwardRef(({ title, texts, canvasRef, cameraRotate, lang }, ref) => {
	const containerRef = useRef(null)
	const lineRef = useRef(null)

	let navigate = useNavigate()

	useEffect(() => {
		if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== "home")
			gsap.to(containerRef.current, { duration: 1, x: 0, ease: Power1.easeInOut, delay: window.innerWidth > 1024 ? 0 : 0.15 })
		if (window.innerWidth > 1024) {
			if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "expertise") lineRef.current.style.width = "20%"
			if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "prendre-rendez-vous")
				lineRef.current.style.width = "25%"
		}
	}, [])

	useImperativeHandle(ref, () => ({
		close: (path) => {
			gsap.to(containerRef.current, {
				duration: 1,
				x: "-100%",
				ease: Power1.easeInOut,
				onComplete: () => {
					navigate(path)
				},
			})
		},
	}))

	return (
		<OverlayItemContainer ref={containerRef}>
			<div className="backButtonContainer">
				<div
					className="backButtonInnerContainer"
					onClick={() => {
						ref.current.close("/home")
						gsap.to(canvasRef.current, {
							duration: 1,
							x: "-25vw",
							ease: Power1.easeInOut,
							onComplete: () => {
								gsap.to(cameraRotate, { duration: 1, current: 1 })
							},
						})
					}}
				>
					<img src="backIcon.png" alt="Back icon"></img>
				</div>
			</div>
			<div className="titleContainer">
				<div className="line" ref={lineRef} />
				<h1 className="title">{title}</h1>
			</div>
			<div className="itemContainer">
				{((title === "DOCTEURS" || title === "DOCTORS") && <APropos texts={texts} />) ||
					(title === "EXPERTISE" && <Expertise texts={texts} />) ||
					((title === "ENFANTS" || title === "CHILDREN") && <Enfants texts={texts} />) ||
					((title === "RENDEZ-VOUS" || title === "APPOINTMENT") && <PrendreRendezVous texts={texts} lang={lang} />) ||
					(title === "CONTACT" && <Contact texts={texts} lang={lang} />)}
			</div>
			{
				window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "prendre-rendez-vous" &&
				<a href="https://www.blackmotion.fr/" target="_blank" without rel="noreferrer"><p className="blackmotion">Created by BLACKMOTION</p></a>
			}
		</OverlayItemContainer>
	)
})

export default OverlayItem
