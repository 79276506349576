import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { Helmet } from "react-helmet"

const PrendreRendezVousContainer = styled.div`
	width: 55%;
	padding-left: calc(25% + 25px);
	box-sizing: content-box;
	white-space: pre-line;
	position: relative;

	h2 {
		margin-top: 12%;
		letter-spacing: 1px;
		font-size: 22px;
	}
	p {
		font-family: "Roobert Regular";
		font-size: 18px;
	}
	.firstP {
		margin-top: 7%;
	}
	.contact,
	.schedules {
		margin-top: 10%;
		display: flex;
		flex-direction: column;
		font-family: "Roobert Regular";
		.address {
			display: flex;
			flex-direction: column;
		}
		a {
			text-decoration: none;
		}
		span {
			margin: 3px 0;
			color: white;
		font-size: 18px;

		}
	}
	.button {
		width: 90%;
		border: 1px white solid;
		margin-top: 10%;
		padding: 10px 40px;
		box-sizing: content-box;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		letter-spacing: 2px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		white-space: nowrap;
		img {
			width: 85px;
			margin-left: 15px;
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			background: white;
			color: grey;
			img {
				filter: invert(65%);
			}
		}
	}

	// RESPONSIVE
	@media only screen and (max-width: 1520px) {
		.button {
			width: calc(80% - 25px);
			text-align: center;
			span {
				padding: 5px 10px;
				white-space: nowrap;
				
			}
			img {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		width: calc(70% + 25px);
		padding-left: calc(10% + 25px);
		.contact,
		.schedules {
			margin-top: 10%;
			display: flex;
			flex-direction: column;
			font-family: "Roobert Regular";
			span {
				font-size: 16px;
				margin: 2px 0;
			}
		}
	}

	@media only screen and (max-width: 640px) {
		h3 {
			display: none;
		}
		.button {
			width: calc(80% - 25px);
			padding: 10px 20px;
			flex-direction: column;
			text-align: center;
			font-size: 10px;
			img {
				display: none;
			}
		}
	}
`
function PrendreRendezVous({ texts }) {
	const isMobile = useMediaQuery({ query: "(max-width: 1520px)" })
	return (
		<PrendreRendezVousContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel | Prendre Rendez-Vous - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="Ouvert du lundi au vendredi. Nous organisons votre venue au cabinet. Bénéficiez d’un bilan complet de votre dentition." />
				<link rel="canonical" href="https://drcuriel.com/prendre-rendez-vous" />
			</Helmet>
			<h2>DOCTEUR PATRICK C. CURIEL</h2>

			<div className="contact">
				<a href={"tel:" + texts["prendreRendezVous-contact-tel"]}>
					<span>{texts["prendreRendezVous-contact-tel"]} </span>
				</a>
				<a href={"mailto:" + texts["prendreRendezVous-contact-mail"]}>
					<span>{texts["prendreRendezVous-contact-mail"]}</span>
				</a>
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.google.fr/maps/place/109+bis+Av.+Charles+de+Gaulle,+92200+Neuilly-sur-Seine/@48.8810491,2.2682062,17z/data=!3m1!4b1!4m5!3m4!1s0x47e6656659c41699:0x2300181997cd67c5!8m2!3d48.8810491!4d2.2703949"
					className="address"
				>
					<span>{texts["prendreRendezVous-contact-adresses"]}</span>
				</a>
			</div>
			<div className="schedules">
				<span>{texts["prendreRendezVous-horaires"]}</span>
			</div>
			<div
				className="button"
				onClick={() => {
					window.open(
						"https://www.doctolib.fr/cabinet-dentaire/neuilly-sur-seine/curiel-orthodontics-cabinet-des-drs-patrick-c-curiel-et-laure-jamot/booking/motives?placeId=practice-38513",
						"_blank"
					)
				}}
			>
				{isMobile && (
					<>
						<span>PRENDRE RENDEZ-VOUS</span>
					</>
				)}
				{!isMobile && (
					<>
						<span>PRENDRE RENDEZ-VOUS AVEC</span>
						<img src="doctolibLogo.png" alt="Doctolib logo"></img>
					</>
				)}
			</div>
			<p className="firstP">{texts["prendreRendezVous-texte"]}</p>
			{/* <p className="blackmotion">Created by BLACKMOTION</p> */}
		</PrendreRendezVousContainer>
	)
}

export default PrendreRendezVous
