import React, { useRef } from "react"
import styled from "styled-components"
import emailjs from "@emailjs/browser"
import { Helmet } from "react-helmet"

const ContactContainer = styled.div`
	width: 45%;
	padding-left: calc(35% + 25px);
	box-sizing: content-box;
	color: #dddddd;
	form {
		display: flex;
		flex-direction: column;
		padding-top: 10%;
		> input,
		textarea {
			margin-top: 5%;
			background: transparent;
			border: none;
			border-bottom: 1px solid #dddddd;
			height: 40px;
			resize: none;
			color: white;
			font-family: "Roobert Regular";
			font-size: 17px;
			&:focus {
				outline: none;
			}
			&::placeholder {
				color: #dddddd;
			}
		}
		textarea {
			height: 70px;
			margin-top: 7%;
		}
		.terms {
			margin-top: 10%;
			display: flex;
			align-items: center;
			font-size: 14px;
			font-family: "Roobert Regular";
			input {
				margin-right: 10px;
				cursor: pointer;
			}
			label {
				cursor: pointer;
			}
		}
		.button {
			margin-top: 10%;
			width: 100%;
			padding-top: 15px;
			padding-bottom: 15px;
			border: 1px solid white;
			text-align: center;
			font-size: 15px;
			letter-spacing: 2px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover {
				background: white;
				color: grey;
			}
		}
	}
	.success {
		font-size: 13px;
		margin-top: 20px;
	}

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: calc(70% + 25px);
		padding-left: calc(10% + 25px);
	}

	@media only screen and (max-width: 640px) {
		width: calc(60% + 25px);
	}
`
function Contact({ lang }) {
	const formRef = useRef(null)
	const nameRef = useRef(null)
	const firstNameRef = useRef(null)
	const phoneNumberRef = useRef(null)
	const eMailRef = useRef(null)
	const messageRef = useRef(null)

	const canSend = useRef(true)
	const successRef = useRef(null)

	return (
		<ContactContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel | Contact - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="01 46 40 01 02 | contact@drcuriel.com | 109 bis avenue Charles de Gaulle, 92200 Neuilly-Sur-Seine, France" />
				<link rel="canonical" href="https://drcuriel.com/contact" />
			</Helmet>
			<form ref={formRef}>
				<input type="text" placeholder={lang === 'fr' ? 'Nom' : 'Name'} ref={nameRef} />
				<input type="text" placeholder={lang === 'fr' ? 'Prénom' : 'Forename'} ref={firstNameRef} />
				<input type="tel" placeholder={lang === 'fr' ? 'Numéro de téléphone' : 'Phone number'} ref={phoneNumberRef} />
				<input type="email" placeholder="E-mail" ref={eMailRef} />
				<textarea rows="20" cols="33" placeholder="Message" ref={messageRef}></textarea>
				<div
					className="button"
					onClick={() => {
						if (canSend.current) {
							if (eMailRef.current.value || phoneNumberRef.current.value) {
								canSend.current = false
								emailjs
									.send(
										"service_we5dm5q",
										"template_ujcp88d",
										{
											firstName: firstNameRef.current.value,
											name: nameRef.current.value,
											message: messageRef.current.value,
											eMail: eMailRef.current.value,
											phoneNumber: phoneNumberRef.current.value,
										},
										"pvhyr79f6pS0rZ18C"
									)
									.then(
										(result) => {
											if (result.status === 200) {
												successRef.current.innerHTML = lang === 'fr' ? 'Le message a bien été envoyé.</br>Le cabinet du Dr.Curiel vous recontactera rapidement.' : 'The message has been sent.</br>Dr.Curie\'s office will contact you shortly.'
												formRef.current.reset()
												canSend.current = true
											}
										},
										(error) => {
											console.log(error.status)
											successRef.current.innerHTML = lang === 'fr' ? 'Erreur, veuillez réessayer ultérieurement.' : 'Error: Please try again later.'
											canSend.current = true
										}
									)
							} else {
								successRef.current.innerHTML = lang === 'fr' ? 'Veuillez remplir les champs.' : 'Please fill in the fields.'
							}
						}
					}}
				>
					ENVOYER
				</div>
			</form>
			<p className="success" ref={successRef}></p>
		</ContactContainer>
	)
}

export default Contact
