import React, { useRef, forwardRef, useImperativeHandle } from "react"
import styled from "styled-components"
import gsap, { Power2 } from "gsap"

const ExpertisePartDocContainer = styled.div`
	width: 85%;
	> .skillContainer {
		border-bottom: 1px solid white;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 25px 0;
		font-family: "Roobert Regular";
		cursor: pointer;
		.docPic {
			width: 150px;
		}
		div, h2 {
			margin-left: 15px;
			.skill {
				font-size: 22px;
				letter-spacing: 2px;
				margin-bottom: 15px;
			}
			.subtitle {
				font-size: 18px;
				margin-bottom: 20px;
				width: 75%;
			}
			.openButtonContainer {
				display: flex;
				justify-content: flex-end;
				.openButton {
					width: 18px;
					filter: invert(100%);
					user-select: none;
				}
			}
		}
		.arg {
			font-size: 14px;
			margin-left: 15px;
			opacity: 0.85;
		}
	}
	.textContainer {
		width: 100%;
		top: 70px;
		left: 0;
		height: 0;
		overflow: hidden;
		margin: 20px 0;
		padding-top: 0;
		font-size: 16px;
		font-family: "Roobert Regular";
		white-space: pre-line;

		.newline {
			height: 10px;
		}

		img {
			width: 250px;
			margin-top: 25px;
		}
	}

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: calc(80% + 25px);
		.arg{
			margin-left: 0 !important;
		}
	}
	@media only screen and (max-width: 640px) {
		width: 85%;
		> .skillContainer {
			flex-direction: column;
			align-items: flex-start;
			.docPic {
				margin-left: 30px;
				margin-bottom: 20px;
			}
			> .skill {
				font-size: 15px;
			}
		}
	}
`

const ExpertisePartDoc = forwardRef(({ title, arg = "", subtitle, text, image, others }, ref) => {
	const textContainerRef = useRef(null)
	const plusLogoRef = useRef(null)
	const isOpen = useRef(false)
	useImperativeHandle(ref, () => ({
		close: () => {
			gsap.to(textContainerRef.current, { duration: 0.9, height: 0, ease: Power2.easeInOut })
			gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "0deg", ease: Power2.easeInOut })
			isOpen.current = false
		},
	}))
	return (
		<ExpertisePartDocContainer>
			<div
				className="skillContainer"
				onClick={() => {
					for (const _other of others) {
						_other.current.close()
					}
					if (!isOpen.current) {
						gsap.to(textContainerRef.current, { duration: 0.9, height: "auto", ease: Power2.easeInOut })
						gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "45deg", ease: Power2.easeInOut })
						isOpen.current = true
					} else {
						gsap.to(textContainerRef.current, { duration: 0.9, height: 0, ease: Power2.easeInOut })
						gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "0deg", ease: Power2.easeInOut })
						isOpen.current = false
					}
				}}
			>
				<img className="docPic" alt="Doctor" src={image} ref={plusLogoRef} />
				<div>
					<h2 className="skill">{title}{arg !== "" && <>{window.innerWidth < 1024 && <br></br>}<span className="arg">{arg}</span></>}</h2>
					<div className="subtitle">{subtitle}</div>
					<div className="openButtonContainer" ><img className="openButton" alt="Open button" src="plus.png" ref={plusLogoRef} /></div>
				</div>
			</div>
			<div className="textContainer" ref={textContainerRef}>
				{text}
			</div>
		</ExpertisePartDocContainer>
	)
})

export default ExpertisePartDoc
