import React, { useRef } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import ExpertisePartDoc from "./ExpertisePartDoc"

const AProposContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	.topContainer {
		padding-left: calc(35% + 25px);
		padding-right: 10%;
		font-size: 14px;
		letter-spacing: 2px;
		margin: 25px 0;
		.subTitle {
			max-width: 50px;
		}
	}
	.bottomContainer {
		display: flex;
		flex-direction: column;
		height: 80%;
		font-family: "Roobert Regular";
		margin-top: 100px;
		padding-left: calc(10% + 25px);
		.imageContainer {
			width: 35%;
			height: 80%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.infosContainer {
			width: 59%;
			height: 80%;
			white-space: pre-line;
			padding-left: 25px;
			font-size: 14px;
			line-height: 20px;
			position: relative;
			.scrollAnim {
				pointer-events: none;
				position: absolute;
				z-index: 20;
				bottom: 0;
				right: 10px;
				height: 80px;
				width: 35px;
			}
			p {
				margin-bottom: 30px;
				margin-right: 50px;
			}
		}
	}
	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		.topContainer {
			padding-left: calc(10% + 25px);
		}
		.bottomContainer {
			flex-direction: column;
			align-items: center;
			padding-left: 25px;
			.imageContainer {
				width: 80%;
				height: 30%;
			}
			.infosContainer {
				width: 80%;
				padding: 6% 0 10% 0;
				> div {
					height: 100%;
					> div {
						height: 100%;
					}
				}
				p {
					margin-right: 20px;
				}
			}
		}
	}
	@media only screen and (max-width: 640px) {
		.topContainer {
			padding-right: calc(10%);
		}
		.bottomContainer {
			flex-direction: column;
			align-items: center;
			height: 100%;
			padding: 0 0 0 10px;
			.imageContainer {
				width: 80%;
				height: 20%;
			}
			.infosContainer {
				height: 100%;
				font-size: 13px;
			}
		}
	}
`
function APropos({ texts }) {
	const doc1 = useRef(undefined)
	const doc2 = useRef(undefined)

	return (
		<AProposContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel | Docteurs - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="Doctor Patrick C. Curiel is graduated from Paris VII University (France) where he obtained his Doctorat in Dental Surgery in 1995." />
				<link rel="canonical" href="https://drcuriel.com/docteurs" />
			</Helmet>
			<div className="bottomContainer">
				<ExpertisePartDoc title={texts["docteurs-1-title"]} arg={"(CEO and Founder)"} subtitle={texts["docteurs-1-subtitle"]} image={"https://curiel-adminzone-6pvs8.ondigitalocean.app/uploads/curiel_f7e60bd748.png?updated_at=2023-05-23T13:15:00.468Z"} text={texts["docteurs-1-text"]} ref={doc1} others={[doc2]} />
				<ExpertisePartDoc title={texts["docteurs-2-title"]} subtitle={texts["docteurs-2-subtitle"]} image={"https://curiel-adminzone-6pvs8.ondigitalocean.app/uploads/doc2_337c61f833.png?updated_at=2023-05-23T13:15:05.247Z"} text={texts["docteurs-2-text"]} ref={doc2} others={[doc1]} />
				{/* <div className="imageContainer">
					<img src="about.png" alt="About"></img>
				</div>
				<div className="infosContainer">
					<Lottie className="scrollAnim" animationData={scrollAnim} loop={true} />
					<Scrollbars>
						<p>{texts["leDocteur-texte"]}</p>
					</Scrollbars>
				</div> */}
			</div>
		</AProposContainer>
	)
}

export default APropos
