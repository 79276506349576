import React, { useRef, forwardRef, useImperativeHandle } from "react"
import styled from "styled-components"
import gsap, { Power2 } from "gsap"

const ExpertisePartContainer = styled.div`
	width: 75%;
	> .skillContainer {
		height: 70px;
		border-bottom: 1px solid white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px 0;
		font-family: "Roobert Regular";
		cursor: pointer;
		> .skill {
			font-size: 22px;
			letter-spacing: 1px;
		}
		img {
			width: 18px;
			filter: invert(100%);
			user-select: none;
		}
	}
	.textContainer {
		width: 100%;
		top: 70px;
		left: 0;
		height: 0;
		overflow: hidden;
		margin: 20px 0;
		padding-top: 0;
		font-size: 16px;
		font-family: "Roobert Regular";
		white-space: pre-line;

		.newline {
			height: 10px;
		}

		img {
			width: 250px;
			margin-top: 25px;
		}
	}

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: calc(80% + 25px);
		.textContainer {
			img {
				width: 100%;
			}
		}
	}
	@media only screen and (max-width: 640px) {
		width: 85%;
		> .skillContainer {
			> .skill {
				font-size: 15px;
			}
		}
	}
`

const Expertise = forwardRef(({ skill, text, image = "", others }, ref) => {
	const textContainerRef = useRef(null)
	const plusLogoRef = useRef(null)
	const isOpen = useRef(false)

	useImperativeHandle(ref, () => ({
		close: () => {
			gsap.to(textContainerRef.current, { duration: 0.9, height: 0, ease: Power2.easeInOut })
			gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "0deg", ease: Power2.easeInOut })
			isOpen.current = false
		},
	}))
	return (
		<ExpertisePartContainer>
			<div
				className="skillContainer"
				onClick={() => {
					for (const _other of others) {
						_other.current.close()
					}
					if (!isOpen.current) {
						gsap.to(textContainerRef.current, { duration: 0.9, height: "auto", ease: Power2.easeInOut })
						gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "45deg", ease: Power2.easeInOut })
						isOpen.current = true
					} else {
						gsap.to(textContainerRef.current, { duration: 0.9, height: 0, ease: Power2.easeInOut })
						gsap.to(plusLogoRef.current, { duration: 0.9, rotate: "0deg", ease: Power2.easeInOut })
						isOpen.current = false
					}
				}}
			>
				<h2 className="skill">{skill}</h2>
				<img alt="Open button" src="plus.png" ref={plusLogoRef} />
			</div>
			<div className="textContainer" ref={textContainerRef}>
				{text}
				<br></br>

				{image !== "" && <img src={image} alt="Enfants"></img>}
			</div>
		</ExpertisePartContainer>
	)
})

export default Expertise
