import React, { useRef } from "react"
import styled from "styled-components"
import ExpertisePart from "./ExpertisePart"
import { Helmet } from "react-helmet"

const ExpertiseContainer = styled.div`
	width: 85%;
	padding-left: calc(20% + 25px);
	box-sizing: content-box;
	padding-top: 7%;
	>h2 {
		margin-bottom: 3%;
		font-size: 22px;
		letter-spacing: 1px;
	}
	.firstCat {
		margin-top: 7%;
	}
	.secondCat {
		margin-top: 15%;
	}

	// RESPONSIVE
	@media only screen and (max-width: 1024px) {
		width: 90%;
		padding-left: calc(10% + 25px);
	}
	@media only screen and (max-width: 640px) {
		padding-left: calc(5% + 25px);
	}
`

function Expertise({ texts }) {
	const skill1Ref = useRef(null)
	const skill2Ref = useRef(null)
	const skill3Ref = useRef(null)
	const skill4Ref = useRef(null)
	return (
		<ExpertiseContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dr. Curiel | Expertise - Orthodontiste Neuilly-sur-Seine</title>
				<meta name="description" content="Leader Mondial des traitements d'orthodontie en technique lingual invisible. Le Dr Curiel est le fondateur du système lingual Harmony (2008), le premier système auto-ligaturant digitalisé et sur mesure, au monde." />
				<link rel="canonical" href="https://drcuriel.com/expertise" />
			</Helmet>
			<ExpertisePart skill={texts["expertise-1-titre"]} text={texts["expertise-1-texte"]} ref={skill1Ref} others={[skill2Ref, skill3Ref, skill4Ref]} />
			<h2 className="firstCat">{texts["expertise-titre"]}</h2>
			<ExpertisePart skill={texts["expertise-2-titre"]} text={texts["expertise-2-texte"]} ref={skill2Ref} others={[skill1Ref, skill3Ref, skill4Ref]} />
			<ExpertisePart skill={texts["expertise-3-titre"]} text={texts["expertise-3-texte"]} ref={skill3Ref} others={[skill1Ref, skill2Ref, skill4Ref]} />
			<ExpertisePart skill={texts["expertise-4-titre"]} text={texts["expertise-4-texte"]} ref={skill4Ref} others={[skill1Ref, skill2Ref, skill3Ref]} />
		</ExpertiseContainer>
	)
}

export default Expertise
