import * as THREE from "three"
import React, { useState, useEffect, Suspense, useRef, forwardRef, useImperativeHandle } from "react"
import { Canvas, extend, useFrame, useThree, useLoader } from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import styled from "styled-components"
import gsap, { Power2 } from "gsap"
import CameraControls from "camera-controls"
import videoExpertise from "./assets/expertise.mp4"
import videoPointer from "./assets/pointer.mp4"

extend({ OrbitControls })
CameraControls.install({ THREE })
extend({ CameraControls })

const Controls = forwardRef(({ cameraRotate }, ref) => {
	const cameraControlsRef = useRef()

	const camera = useThree((state) => state.camera)
	const gl = useThree((state) => state.gl)
	useFrame((state, delta) => {
		cameraControlsRef.current.update(delta)
		if (cameraRotate.current !== 0) cameraControlsRef.current.azimuthAngle -= 0.002 * cameraRotate.current
	})

	useEffect(() => {
		switch (window.location.href.substring(window.location.href.lastIndexOf("/") + 1)) {
			case "":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: -2.077, polarAngle: 1.569 })
				break
			case "home":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: -2.077, polarAngle: 1.569 })
				gsap.to(cameraRotate, { duration: 1, current: 1 })
				break
			case "docteurs":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: 2.589, polarAngle: 1.45 })
				break
			case "expertise":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: 0, polarAngle: 1.575 })
				break
			case "prendre-rendez-vous":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: 1.008, polarAngle: 1.51 })
				break
			case "contact":
				gsap.to(cameraControlsRef.current, { duration: 0, azimuthAngle: -1.886, polarAngle: 1.565 })
				break
			default:
		}
	}, [cameraRotate])

	useImperativeHandle(ref, () => ({
		slide: (path) => {
			let findClosestRad = (target) => {
				let closestValue = target
				do {
					closestValue -= 2 * Math.PI
				} while (Math.abs(cameraControlsRef.current.azimuthAngle) - Math.abs(closestValue) > 2 * Math.PI)
				return closestValue
			}
			switch (path) {
				case "home":
					gsap.to(cameraControlsRef.current, {
						duration: 2,
						azimuthAngle: findClosestRad(-2.077),
						polarAngle: 1.569,
						ease: Power2.easeInOut,
						onComplete: () => {
							gsap.to(cameraRotate, { duration: 1, current: 1 })
						},
					})
					break
				case "docteurs":
					cameraRotate.current = 0
					gsap.to(cameraControlsRef.current, { duration: 2, azimuthAngle: findClosestRad(2.589), polarAngle: 1.45, ease: Power2.easeInOut })
					gsap.to(cameraRotate, { duration: 1, current: 0 })
					break
				case "expertise":
					cameraRotate.current = 0
					gsap.to(cameraControlsRef.current, { duration: 2, azimuthAngle: findClosestRad(0), polarAngle: 1.575, ease: Power2.easeInOut })
					gsap.to(cameraRotate, { duration: 1, current: 0 })
					break
				case "prendre-rendez-vous":
					cameraRotate.current = 0
					gsap.to(cameraControlsRef.current, { duration: 2, azimuthAngle: findClosestRad(1.008), polarAngle: 1.51, ease: Power2.easeInOut })
					gsap.to(cameraRotate, { duration: 1, current: 0 })
					break
				case "contact":
					cameraRotate.current = 0
					gsap.to(cameraControlsRef.current, {
						duration: 2,
						azimuthAngle: findClosestRad(-1.886),
						polarAngle: 1.565,
						ease: Power2.easeInOut,
					})
					gsap.to(cameraRotate, { duration: 1, current: 0 })
					break
				default:
			}
		},
	}))
	return (
		<cameraControls
			ref={cameraControlsRef}
			args={[camera, gl.domElement]}
			azimuthRotateSpeed={-0.4}
			polarRotateSpeed={-0.4}
			dampingFactor={0.2}
			dollySpeed={0}
			truckSpeed={0}
		/>
	)
})

function Dome() {
	const texture = useLoader(THREE.TextureLoader, window.innerWidth < 1024 ? "/roomMobile.jpg" : "/room.jpg")
	texture.wrapS = THREE.RepeatWrapping
	texture.repeat.x = -1
	texture.mapping = THREE.EquirectangularReflectionMapping
	texture.minFilter = THREE.LinearFilter
	return (
		<mesh>
			<sphereBufferGeometry attach="geometry" args={[500, 64, 32]} />
			<meshBasicMaterial attach="material" map={texture} side={THREE.BackSide} />
		</mesh>
	)
}

const View360Container = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	.canvasInnerContainer {
		width: 150%;
		height: 100%;
	}
`

const View360 = forwardRef(({ canvasRef, cameraRotate, menuRef }, ref) => {
	const controlsRef = useRef(null)
	const isClicking = useRef(false)
	const timeLine = useRef(gsap.timeline())
	timeLine.current.pause()
	timeLine.current.to(cameraRotate, { duration: 1, current: 1 })

	const pointerAPropos = useLoader(THREE.TextureLoader, "./pointer-a-propos.png")
	const pointerContact = useLoader(THREE.TextureLoader, "./pointer-contact.png")
	const pointerExpertise = useLoader(THREE.TextureLoader, "./pointer-expertise.png")
	const pointerPrendreRendezVous = useLoader(THREE.TextureLoader, "./pointer-prendre-rendez-vous.png")
	const details = useLoader(THREE.TextureLoader, "./details.png")

	const [hovered, setHovered] = useState(false)
	const [video] = useState(() => {
		const vid = document.createElement("video")
		vid.src = videoExpertise
		vid.crossOrigin = "Anonymous"
		vid.playsInline = true
		vid.loop = true
		vid.muted = true
		vid.play()
		return vid
	})
	const [videoPointerState] = useState(() => {
		const vid = document.createElement("video")
		vid.src = videoPointer
		vid.crossOrigin = "Anonymous"
		vid.playsInline = true
		vid.loop = true
		vid.muted = true
		vid.play()
		return vid
	})

	useImperativeHandle(ref, () => ({
		slide: (path) => {
			if (path === "home") {
				gsap.to(canvasRef.current, {
					duration: 2,
					x: "-25vw",
					ease: Power2.easeInOut,
				})
			} else {
				gsap.to(canvasRef.current, { duration: 2, x: "0vw", ease: Power2.easeInOut })
			}
			controlsRef.current.slide(path)
		},
	}))

	useEffect(() => {
		if (
			window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "home" ||
			window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === ""
		) {
			canvasRef.current.style.transform = "translateX(-25vw)"
		}
	}, [canvasRef])

	useEffect(() => {
		document.body.style.cursor = hovered ? "pointer" : "auto"
	}, [hovered])

	return (
		<View360Container>
			<div className="canvasInnerContainer">
				<Canvas
					camera={{ position: [0, 0, 0.00001] }}
					linear
					flat
					ref={canvasRef}
					onMouseDown={() => {
						timeLine.current.pause()
						cameraRotate.current = 0
						isClicking.current = true
					}}
					onMouseUp={() => {
						setTimeout(() => {
							if (!isClicking.current && window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "home")
								timeLine.current.restart()
						}, 3000)
						isClicking.current = false
					}}
					onTouchStart={() => {
						timeLine.current.pause()
						cameraRotate.current = 0
						isClicking.current = true
					}}
					onTouchEnd={() => {
						setTimeout(() => {
							if (!isClicking.current && window.location.href.substring(window.location.href.lastIndexOf("/") + 1) === "home")
								timeLine.current.restart()
						}, 3000)
						isClicking.current = false
					}}
				>
					<Controls
						cameraRotate={cameraRotate}
						enableZoom={false}
						enablePan={false}
						enableDamping
						dampingFactor={0.05}
						rotateSpeed={0.4}
						ref={controlsRef}
					/>
					<Suspense>
						<Dome />
						<mesh rotation={[0, (Math.PI / 4) * 3.315, 0]} position={[1.565, 0.232, 4.2]}>
							<planeGeometry args={[0.91, 1.727]} />
							<meshBasicMaterial side={THREE.DoubleSide} brigh>
								<videoTexture attach="map" args={[video]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, (Math.PI / 4) * 3.265 + Math.PI, 0]} position={[0.403, 0.405, -4.2]}>
							<planeGeometry args={[0.941, 1.745]} />
							<meshBasicMaterial side={THREE.DoubleSide} brigh>
								<videoTexture attach="map" args={[video]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, (Math.PI / 4) * 3.3, 0]} position={[-1, 1.2, 1.6]}>
							<planeGeometry args={[1.8, 0.051]} />
							<meshBasicMaterial transparent color={"#9c958e"} side={THREE.DoubleSide} brigh alphaMap={details} />
						</mesh>
						{/* CONTACT */}
						<mesh
							rotation={[0, -2, 0]}
							position={[3.5, -0.53, 1.3]}
							onClick={() => {
								menuRef.current.redirect("contact")
							}}
							onPointerOver={() => setHovered(true)}
							onPointerOut={() => setHovered(false)}
						>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh>
								<videoTexture attach="alphaMap" args={[videoPointerState]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, -2, 0]} position={[3.5, -0.53, 1.3]}>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh alphaMap={pointerContact} />
						</mesh>
						{/* RDV */}
						<mesh
							rotation={[0, 1.1, 0]}
							position={[-2.8, -0.36, -2.2]}
							onClick={() => {
								menuRef.current.redirect("prendre-rendez-vous")
							}}
							onPointerOver={() => setHovered(true)}
							onPointerOut={() => setHovered(false)}
						>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh>
								<videoTexture attach="alphaMap" args={[videoPointerState]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, 1.1, 0]} position={[-2.8, -0.36, -2.2]}>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh alphaMap={pointerPrendreRendezVous} />
						</mesh>
						{/* EXPERTISE */}
						<mesh
							rotation={[0, -0.4, 0]}
							position={[1, -0.3, -3.5]}
							onClick={() => {
								menuRef.current.redirect("expertise")
							}}
							onPointerOver={() => setHovered(true)}
							onPointerOut={() => setHovered(false)}
						>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh>
								<videoTexture attach="alphaMap" args={[videoPointerState]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, -0.4, 0]} position={[1, -0.3, -3.5]}>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh alphaMap={pointerExpertise} />
						</mesh>
						{/* A PROPOS */}
						<mesh
							rotation={[0, 2.5, 0]}
							position={[-2.7, -0.5, 3]}
							onClick={() => {
								menuRef.current.redirect("docteurs")
							}}
							onPointerOver={() => setHovered(true)}
							onPointerOut={() => setHovered(false)}
						>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh>
								<videoTexture attach="alphaMap" args={[videoPointerState]} />
							</meshBasicMaterial>
						</mesh>
						<mesh rotation={[0, 2.5, 0]} position={[-2.7, -0.5, 3]}>
							<planeGeometry args={[2.0826, 0.9]} />
							<meshBasicMaterial transparent side={THREE.DoubleSide} brigh alphaMap={pointerAPropos} />
						</mesh>
					</Suspense>
				</Canvas>
			</div>
		</View360Container>
	)
})

export default View360
