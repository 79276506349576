import { useRef, useEffect } from "react"
import * as PIXI from "pixi.js"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap, { Power1, Power3 } from "gsap"
// import { useMediaQuery } from "react-responsive"

import FrontTexture from "../assets/front.png"
import FrontDepthTexture from "../assets/front-depth.png"
import BackTexture from "../assets/back.png"
import DoorTexture from "../assets/door.png"
import DoorMaskTexture from "../assets/door-mask.png"

import FrontTextureMobile from "../assets/front-mobile.png"
import BackTextureMobile from "../assets/back-mobile.png"
import DoorTextureMobile from "../assets/door-mobile.png"
import DoorMaskTextureMobile from "../assets/door-mask-mobile.png"

const ParallaxlandingContainer = styled.div`
	width: 200%;
	height: 100%;
	overflow: hidden;
	position: relative;
	pointer-events: auto;
	canvas {
		width: 100vw;
		height: 100vh;
		transform: scale(1.02);
	}
	.innerContainer {
		width: 90%;
		height: 200px;
		height: auto;
		position: absolute;
		left: 50%;
		bottom: 15%;
		transform: translateX(-50%);
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		letter-spacing: 1px;
		z-index: 200;
		h2 {
			font-size: 36px;
			font-family: "Roobert Regular";
		}
		.enterButton {
			border: 1px solid #5f4e41;
			border-radius: 100px;
			background: none;
			color: #5f4e41;
			padding: 12px 75px;
			font-size: 12px;
			letter-spacing: 2px;
			margin-bottom: 25px;
			&.none {
				cursor: no-drop;
				pointer-events: none;
			}
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			overflow: hidden;
			&:hover {
				background: rgba(255, 255, 255, 0.2);
			}
			&:after {
				background: #fff;
				content: "";
				height: 155px;
				left: -75px;
				opacity: 0.2;
				position: absolute;
				top: -50px;
				transform: rotate(35deg);
				width: 45px;
				z-index: -10;
				filter: blur(8px);
				animation: 3s linear 0s infinite forwards bright;
			}
		}
		@keyframes bright {
			0% {
				left: -75px;
			}
			25% {
				left: 120%;
			}
			100% {
				left: 120%;
			}
		}
	}

	// RESPONSIVE
	@media only screen and (max-width: 1440px) {
		.innerContainer {
			bottom: 14%;
		}
	}
	@media only screen and (max-height: 750px) {
		.innerContainer {
			bottom: 10%;
			h2 {
				font-size: 26px;
			}
			.enterButton {
				padding: 12px 70px;
				font-size: 10px;
				letter-spacing: 2px;
				margin-bottom: 25px;
				overflow: hidden;
			}
		}
	}
	@media only screen and (max-width: 1024px) {
		width: 100vw;
	}
	@media only screen and (max-width: 640px) {
		.innerContainer {
			bottom: 13%;
			h2 {
				font-size: 22px;
				margin-bottom: 5vh;
			}
		}
	}
	@media only screen and (max-width: 640px) and (max-height: 750px) {
		.innerContainer {
			bottom: 3%;
		}
	}
`

PIXI.Sprite.prototype.setSize = function (width, height, type) {
	var texture = { width: this.texture.width, height: this.texture.height },
		targetRatio = width / height,
		textureRatio = this.texture.width / this.texture.height,
		scale,
		pos = new PIXI.Point(0, 0)

	if (type === "cover") {
		if (targetRatio > textureRatio) {
			scale = width / texture.width
			pos.y = -(texture.height * scale - height) / 2
		} else {
			scale = height / texture.height
			pos.x = -(texture.width * scale - width) / 2
		}
	} else {
		if (targetRatio > textureRatio) {
			scale = height / texture.height
			pos.x = -(texture.width * scale - width) / 2
		} else {
			scale = width / texture.width
			pos.y = -(texture.height * scale - height) / 2
		}
	}

	this.scale.set(scale)
	this.position = pos
	this.anchor.set(0.5)
	this.position.x += this.width / 2 - 5
	this.position.y += this.height / 2
}

function ParallaxLanding({ menuRef, view360Ref, audio, setAudioPlaying }) {
	const domContainer = useRef(null)
	const buttonRef = useRef(null)
	const innerContainerRef = useRef(null)

	let navigate = useNavigate()

	useEffect(() => {
		menuRef.current.display("none")

		const app = new PIXI.Application({
			width: window.innerWidth,
			height: window.innerHeight,
			backgroundColor: 0xffffff,
			resolution: 2,
			autoResize: true,
		})
		domContainer.current.appendChild(app.view)

		const loader = new PIXI.Loader()

		loader
			.add(FrontTexture)
			.add(FrontDepthTexture)
			.add(BackTexture)
			.add(DoorTexture)
			.add(DoorMaskTexture)
			.add(FrontTextureMobile)
			.add(BackTextureMobile)
			.add(DoorTextureMobile)
			.add(DoorMaskTextureMobile)
			.load(setup)

		function setup() {
			app.renderer.resize(window.innerWidth, window.innerHeight)

			// the resources hash provided by the loader:
			let resources = loader.resources

			// Create images
			let front, frontDepthMap, back, bluredBack, door, doorMask
			// Desktop
			if (window.innerWidth > 1024) {
				front = new PIXI.Sprite(resources[FrontTexture].texture)
				frontDepthMap = new PIXI.Sprite(resources[FrontDepthTexture].texture)
				back = new PIXI.Sprite(resources[BackTexture].texture)
				bluredBack = new PIXI.Sprite(resources[BackTexture].texture)
				door = new PIXI.Sprite(resources[DoorTexture].texture)
				doorMask = new PIXI.Sprite(resources[DoorMaskTexture].texture)
			}
			// Mobile
			else {
				front = new PIXI.Sprite(resources[FrontTextureMobile].texture)
				back = new PIXI.Sprite(resources[BackTextureMobile].texture)
				bluredBack = new PIXI.Sprite(resources[BackTextureMobile].texture)
				door = new PIXI.Sprite(resources[DoorTextureMobile].texture)
				doorMask = new PIXI.Sprite(resources[DoorMaskTextureMobile].texture)
			}

			// Setup textures size & resize event
			function setupTexturesSizes() {
				app.renderer.resize(window.innerWidth, window.innerHeight)

				front.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")
				if (window.innerWidth > 1024) frontDepthMap.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")
				back.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")
				bluredBack.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")
				door.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")

				doorMask.setSize(app.renderer.width / 2, app.renderer.height / 2, "cover")
			}
			window.addEventListener("resize", setupTexturesSizes)
			setupTexturesSizes()

			// Blured door
			bluredBack.filters = [new PIXI.filters.BlurFilter(32, 10)]

			// Parallax
			let displacementFilter
			if (window.innerWidth > 1024) {
				displacementFilter = new PIXI.filters.DisplacementFilter(frontDepthMap)
				app.stage.filters = [displacementFilter]
				window.onmousemove = function (e) {
					displacementFilter.scale.x = (window.innerWidth / 2 - e.clientX) / 30
					displacementFilter.scale.y = (window.innerHeight / 2 - e.clientY) / 30
				}
			}

			app.stage.addChild(back)
			app.stage.addChild(bluredBack)
			app.stage.addChild(doorMask)
			bluredBack.mask = doorMask
			app.stage.addChild(door)

			if (window.innerWidth > 1024) app.stage.addChild(frontDepthMap)
			app.stage.addChild(front)

			// White fade transition
			let whiteFade = new PIXI.Graphics()
			whiteFade.beginFill(0xffffff)
			whiteFade.drawRect(0, 0, app.renderer.width, app.renderer.height)
			whiteFade.alpha = 0
			app.stage.addChild(whiteFade)

			let transition = () => {
				audio.volume = 0.1
				audio.play()
				setAudioPlaying(true)

				buttonRef.current.removeEventListener("click", transition)
				view360Ref.current.slide("home")

				gsap.to(innerContainerRef.current, { duration: 2, opacity: 0, ease: Power3.easeOut })

				gsap.to(doorMask.position, {
					duration: 4,
					x: window.innerWidth > 1024 ? doorMask.position.x + doorMask.width / 3.1 : doorMask.position.x + doorMask.width / 1.1,
					ease: Power1.easeInOut,
				})
				gsap.to(door.position, {
					duration: 4,
					x: window.innerWidth > 1024 ? doorMask.position.x + doorMask.width / 3.1 : doorMask.position.x + doorMask.width / 1.1,
					ease: Power1.easeInOut,
				})
				gsap.to(whiteFade, {
					duration: 2,
					delay: 1.5,
					alpha: 1,
					ease: Power1.easeIn,
					onComplete: () => {
						menuRef.current.display("flex")
						domContainer.current.style.pointerEvents = "none"
					},
				})
				gsap.to(front.scale, {
					duration: 1.7,
					delay: 2,
					x: front.scale.x * 1.2,
					y: front.scale.y * 1.2,
					ease: Power3.easeIn,
				})
				gsap.to(back.scale, {
					duration: 1.7,
					delay: 2,
					x: back.scale.x * 1.1,
					y: back.scale.y * 1.1,
					ease: Power3.easeIn,
				})

				gsap.to(domContainer.current, {
					duration: 2,
					delay: 4.5,
					opacity: 0,
					ease: Power1.easeOut,
					onComplete: () => {
						navigate("/home")
					},
				})
			}
			buttonRef.current.addEventListener("click", transition)
		}

		// Button animation
		var tl = gsap.timeline({ repeat: -1 }).yoyo(true)
		tl.to(buttonRef.current, { scale: window.innerHeight > 750 ? 1.2 : 1.1, duration: 2, ease: Power1.easeIn })
		tl.to(buttonRef.current, { scale: window.innerHeight > 750 ? 0.8 : 0.9, duration: 2, ease: Power1.easeOut })
	}, [audio, menuRef, navigate, setAudioPlaying, view360Ref])

	return (
		<ParallaxlandingContainer className="test" ref={domContainer}>
			<div className="innerContainer" ref={innerContainerRef}>
				<div className="enterButton" ref={buttonRef}>
					DÉCOUVRIR
				</div>
				{/* <div className="enterButton none" ref={buttonRef}>
					COMING SOON
				</div> */}
			</div>
		</ParallaxlandingContainer>
	)
}

export default ParallaxLanding
