import { useState, useRef, useEffect } from "react"
import Menu from "./Menu/Menu"
import View360 from "./View360"
import Overlay from "./Overlay"
import { BrowserRouter } from "react-router-dom"
import styled from "styled-components"
import gsap, { Power2 } from "gsap"

import Music from "./assets/music.mp3"
import curielLoader from "./assets/curielLoader.mp4"
import curielLoaderMobile from "./assets/curielLoaderMobile.mp4"

const AppContainer = styled.div`
	height: 100%;
	.whiteFade {
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 1;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		background: white;
		z-index: 250;
		img {
			width: 15%;
			height: auto;
		}
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.loadingBarContainer {
			position: absolute;
			left: 50%;
			bottom: 7%;
			transform: translateX(-46%);
			width: 500px;
			height: 3px;
			background: lightgrey;
			.loadingBar {
				background: grey;
				width: 0;
				height: 100%;
				transform-origin: left;
				transition: width 0.7s ease-out;
			}
		}
		@media only screen and (max-width: 640px) {
			.loadingBarContainer {
				width: 80%;
				transform: translateX(-50%);
			}
		}
	}
	.soundToggleButton {
		position: absolute;
		bottom: 17px;
		right: 20px;
		width: 30px;
		height: 30px;
		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
`

function App() {
	const [lastRoute, setLastRoute] = useState(null)
	const [texts, setTexts] = useState([])
	const [audioPlaying, setAudioPlaying] = useState(false)
	let storageLang = null
	if (localStorage.getItem('lang')) {
		storageLang = localStorage.getItem('lang')
	}
	else {
		localStorage.setItem('lang', 'fr');
		storageLang = 'fr'
	}
	const [lang, setLang] = useState(storageLang)

	const overlayRef = useRef(null)
	const view360Ref = useRef(null)
	const canvasRef = useRef(null)
	const menuRef = useRef(null)
	const whiteFadeRef = useRef(null)
	const curielGifRef = useRef(null)
	const loadingBarRef = useRef(null)
	const loadingBarContainerRef = useRef(null)

	const audio = useRef(new Audio(Music))
	audio.current.pause = true
	audio.current.loop = true

	const cameraRotate = useRef(0)

	useEffect(() => {
		// LOAD 360 IMAGE
		var req = new XMLHttpRequest()
		req.open("GET", "https://xp.blackmotion.fr/curiel/room.jpg", true)
		req.responseType = "blob"

		req.onprogress = function (event) {
			gsap.to(loadingBarRef.current, { duration: 0.5, width: (event.loaded / event.total) * 100 + "%", ease: Power2.easeInOut })
		}

		req.onload = function () {
			gsap.to(whiteFadeRef.current, {
				duration: 0.5,
				opacity: 0,
				delay: 1,
				ease: Power2.easeOut,
				onComplete: () => {
					whiteFadeRef.current.style.display = "none"
					curielGifRef.current.style.display = "none"
					loadingBarContainerRef.current.style.display = "none"
				},
			})
		}
		req.onerror = function () {}

		req.send()

		// FETCH API

		fetch(`https://curiel-adminzone-6pvs8.ondigitalocean.app/api/texts?locale=${lang}`, {
			method: "GET",
			headers: new Headers(),
			mode: "cors",
			cache: "default",
		})
			.then(function (response) {
				return response.json()
			})
			.then(function (json) {
				let data = {}
				for (const _element of json.data) {
					data[_element.attributes.txt] = _element.attributes.value
				}
				setTexts(data)
			})
	}, [lang])

	const transitionToLanding = () => {
		whiteFadeRef.current.style.display = "flex"
		whiteFadeRef.current.style.pointerEvents = "auto"
		gsap.to(whiteFadeRef.current, {
			duration: 2,
			opacity: 1,
			ease: Power2.easeIn,
			onComplete: () => {
				menuRef.current.goToLanding()
				whiteFadeRef.current.style.pointerEvents = "none"
				gsap.to(whiteFadeRef.current, {
					duration: 2,
					delay: 1,
					opacity: 0,
					ease: Power2.easeOut,
					onComplete: () => {
						whiteFadeRef.current.style.display = "none"
					},
				})
			},
		})
	}

	return (
		<AppContainer className="App">
			<div className="whiteFade" ref={whiteFadeRef}>
				<video muted autoPlay loop playsInline ref={curielGifRef}>
					{window.innerWidth > 1024 && <source src={curielLoader} type="video/mp4" />}
					{window.innerWidth <= 1024 && <source src={curielLoaderMobile} type="video/mp4" />}
				</video>
				<div className="loadingBarContainer" ref={loadingBarContainerRef}>
					<div className="loadingBar" ref={loadingBarRef}></div>
				</div>
			</div>
			<BrowserRouter>
				<View360 canvasRef={canvasRef} cameraRotate={cameraRotate} menuRef={menuRef} ref={view360Ref} />
				<Overlay
					lastRoute={lastRoute}
					canvasRef={canvasRef}
					overlayRef={overlayRef}
					menuRef={menuRef}
					view360Ref={view360Ref}
					cameraRotate={cameraRotate}
					audio={audio.current}
					texts={texts}
					lang={lang}
					setAudioPlaying={setAudioPlaying}
				/>
				<Menu
					setLastRoute={setLastRoute}
					view360Ref={view360Ref}
					overlayRef={overlayRef}
					transitionToLanding={transitionToLanding}
					setLang={setLang}
					lang={lang}
					texts={texts}
					ref={menuRef}
				/>
				<div
					className="soundToggleButton"
					onClick={() => {
						if (audio.current.pause) audio.current.play()
						audioPlaying ? (audio.current.volume = 0) : (audio.current.volume = 0.1)
						setAudioPlaying((audioPlaying) => !audioPlaying)
					}}
				>
					<img src={audioPlaying ? "soundOn.png" : "soundOff.png"} alt="Sound toggle button" />
				</div>
			</BrowserRouter>
		</AppContainer>
	)
}

export default App
